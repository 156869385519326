import React from 'react'
import {
  AppLayout,
  BreadcrumbGroup,
  ButtonDropdownProps,
  SideNavigation,
  SideNavigationProps,
  TopNavigation
} from '@amzn/awsui-components-react'
import salesforce from '../assets/img/salesforce.svg'
import {HeaderPortal} from '../common/HeaderPortal'
import logo_white from '../assets/img/AWS_OneTeam.svg'
import {Outlet} from 'react-router-dom'
import {Notifications} from '../common/Notifications'
import {JsonObject} from '../App'
interface Props {
  user: JsonObject,
  token: string
}
const OneTeam: React.FC<Props> = ({user, token}: Props) => {
  const i18nStrings = {
    searchIconAriaLabel: 'Search',
    searchDismissIconAriaLabel: 'Close search',
    overflowMenuTriggerText: 'More',
    overflowMenuTitleText: 'All',
    overflowMenuBackIconAriaLabel: 'Back',
    overflowMenuDismissIconAriaLabel: 'Close menu'
  }

  const profileActions = [
    {type: 'button', id: 'profile', text: 'Profile'},
    {type: 'button', id: 'preferences', text: 'Preferences'},
    {type: 'button', id: 'security', text: 'Security'},
    {
      type: 'menu-dropdown',
      id: 'support-group',
      text: 'Support',
      items: [
        {
          id: 'documentation',
          text: 'Documentation',
          href: '#',
          external: true,
          externalIconAriaLabel: ' (opens in new tab)'
        },
        {
          id: 'feedback',
          text: 'Feedback',
          href: '#',
          external: true,
          externalIconAriaLabel: ' (opens in new tab)'
        },
        {id: 'support', text: 'Customer support'}
      ]
    },
    {type: 'button', id: 'signout', text: 'Sign out'}
  ]

  const navItems: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: 'Dashboard',
      href: '/dashboard'
    },
    {
      type: 'section',
      text: 'Activities',
      items: [
        {type: 'link', text: 'Take Meeting Notes', href: '#/note'},
        {type: 'link', text: 'Plan an Engagement', href: '#/engagement'},
        {type: 'link', text: 'Write a PFR', href: '#/pfr'},
        {type: 'link', text: 'Write a Blog Post', href: '#/post'},
        {type: 'link', text: 'Write a Talk', href: '#/talk'},
      ]
    }, {
      type: 'section',
      text: 'Accounts',
      items: [
        {type: 'link', text: 'HUK', href: 'huk'},
        {type: 'link', text: 'DEVK', href: 'devk'},
        {type: 'link', text: 'msg', href: 'msg'}
      ]
    }, {
      type: 'section',
      text: 'Assets',
      items: [
        {type: 'link', text: 'Blog Posts', href: '#/posts'},
        {type: 'link', text: 'Talks', href: '#/talks'},
      ]

    }
  ]

  const breadcrumbs = [
    {
      text: 'OneTeam',
      href: '/'
    },
    {
      text: 'HUK',
      href: '/huk'
    }
  ]
  const bookmarks: ButtonDropdownProps.Item[] =
    [{
      id: 'salesforce',
      text: 'Salesforce',
      href: 'https://aws-crm.my.salesforce.com/',
      iconSvg: salesforce
    }]

  return (
    <div>
      <HeaderPortal>
        <TopNavigation
          identity={{
            href: '/',
            title: 'OneTeam',
            logo: {src: logo_white, alt: 'AWS logo'}
          }}
          i18nStrings={i18nStrings}
          utilities={[
            {
              type: 'button',
              iconName: 'notification',
              ariaLabel: 'Notifications',
              badge: true,
              disableUtilityCollapse: true
            },
            {
              type: 'menu-dropdown',
              text: 'Bookmarks',
              iconName: 'external',

              items: bookmarks
            },
            {type: 'button', iconName: 'settings', title: 'Settings', ariaLabel: 'Settings'},
            {
              type: 'menu-dropdown',
              text: user.nickname as string,
              description: '',
              iconName: 'user-profile',
              items: profileActions
            }
          ]}/>
      </HeaderPortal>
      <AppLayout
        stickyNotifications
        toolsHide
        ariaLabels={{navigationClose: 'close'}}

        navigation={<SideNavigation activeHref="#/pages" items={navItems}/>}
        breadcrumbs={<BreadcrumbGroup items={breadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs"/>}
        contentType="table"
        content={<Outlet/>}
        notifications={<Notifications token={token}/>}
      />
    </div>)
}

export default OneTeam