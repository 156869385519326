import React from 'react'
import './ErrorMessage.css'
interface Props {
  type?: string | null | undefined
  description?: string | null | undefined
}

export default function ErrorMessage(props: Props) {
  return <div className="error">
    <div className="error-row"><span>Error{props.description ? `: ${props.description}` : ''}</span></div>
  </div>
}