import React, {
  useContext,
  useState
} from 'react'
import Flashbar, {FlashbarProps} from '@amzn/awsui-components-react/polaris/flashbar'
import useWebSocket from 'react-use-websocket'
import config from '../config'
import provideHandler from './handleNotification'
import {LoggerContext} from './logger'

interface Props {
  token: string
}

export function Notifications(props: Props) {
  const [notifications, setNotifications] = useState([] as Array<FlashbarProps.MessageDefinition>)
  const dismissNotification = (idToDismiss: string) => {
    const nextNotifications = notifications.filter(notification => notification.id !== idToDismiss)
    setNotifications(nextNotifications)
  }
  const logger = useContext(LoggerContext)
  useWebSocket(config.websocketUrl, {
    protocols: ['Authorization', props.token],
    onMessage: provideHandler({notifications, setNotifications, dismissNotification}),
    onOpen: (event: Event) => logger.info({event}, ''),
    onClose: (event: Event) => logger.info({event},'' ),
    onError: (event: Event) => logger.error({event}, '' ),
    retryOnError: true,
    reconnectAttempts: 10
  })

  return <Flashbar items={notifications}/>
}
