/* eslint-disable @typescript-eslint/no-explicit-any */ 
import LogApi from '../api/log-api'
import {createContext} from 'react'
import * as bunyan from 'browser-bunyan'
export interface LogEvent {
  type: string
}
export default class Logger {
  private name: string
  private logger: bunyan.Logger

  constructor(name = "test", level = bunyan.FATAL) {
    this.name = name
    this.logger = bunyan.createLogger({name, level })
  }

  trace(fields: unknown, msg: string, ...args: any[]): void
  trace(err: Error, msg?: string, ...args: any[]): void
  trace(msg: string, ...args: any[]): void
  trace(first: unknown|Error|string, second?: string|any, third?: any[]): void{
    this.logger.trace.apply<bunyan.Logger, any[], void>(this.logger, [first, second, third])
  }
  
  debug(fields: unknown, msg: string, ...args: any[]): void
  debug(err: Error, msg?: string, ...args: any[]): void
  debug(msg: string, ...args: any[]): void
  debug(first: unknown|Error|string, second?: string|any, third?: any[]): void{
    this.logger.debug.apply<bunyan.Logger, any[], void>(this.logger, [first, second, third])
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  info(fields: unknown, msg: string, ...args: any[]): void
  info(err: Error, msg?: string, ...args: any[]): void
  info(msg: string, ...args: any[]): void
  info(first: unknown|Error|string, second?: string|any, third?: any[]): void{
    const args = [first, second, third].filter(n => n != undefined)
    // eslint-disable-next-line prefer-spread
    this.logger.info.apply<bunyan.Logger, any[], void>(this.logger, args)
  }

  warn(fields: unknown, msg: string, ...args: any[]): void
  warn(err: Error, msg?: string, ...args: any[]): void
  warn(msg: string, ...args: any[]): void
  warn(first: unknown|Error|string, second?: string|any, third?: any[]): void{
    this.logger.warn.apply<bunyan.Logger, any[], void>(this.logger, [first, second, third])
  }

  error(fields: unknown, msg: string, ...args: any[]): void
  error(err: Error, msg?: string, ...args: any[]): void
  error(msg: string, ...args: any[]): void
  error(first: unknown|Error|string, second?: string|any, third?: any[]): void{
    this.logger.error.apply<bunyan.Logger, any[], void>(this.logger, [first, second, third])
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  fatal(fields: unknown, msg: string, ...args: any[]): void
  fatal(err: Error, msg?: string, ...args: any[]): void
  fatal(msg: string, ...args: any[]): void
  fatal(first: unknown|Error|string, second?: string|any, third?: any[]): void{
    this.logger.fatal.apply<bunyan.Logger, any[], void>(this.logger, [first, second, third])
  }
  
  logEvent<T extends LogEvent>(payload: T) {
    this.logger.info(payload, 'Event published')
    if (this.name !== 'test') {
      LogApi.postLog([{payload, timestamp: new Date().valueOf()}])
    }
  }
}

export const LoggerContext = createContext(new Logger())
