import React, {
  useContext,
  useEffect,
  useState
} from 'react'

import AuthContext from './common/amplifyAuth'
import OneTeam from './oneteam/OneTeam'
import {AuthUser,} from '@aws-amplify/auth'
import { Hub } from "aws-amplify/utils"

import './App.css'
import {LoggerContext} from './common/logger'
import {ensure} from './util/ensure'
import LoadingIndicator from './LoadingIndicator'
import ErrorMessage from './ErrorMessage'
import {Amplify} from 'aws-amplify'
import UserApi from './api/user-api'

enum StartSequence {
  START = 'START',
  INIT = 'INIT',
  LOGGING_IN = 'LOGGING_IN',
  LOGGED_IN = 'LOGGED_IN',
  LOADING_PROFILE = 'LOADING_PROFILE',
  PROFILE_LOADED = 'PROFILE_LOADED',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

type Json = null | string | number | boolean | Json[] | JsonObject;
export type JsonObject = {
  [name: string]: Json;
};


export default function App() {
  document.body.className = 'awsui-visual-refresh awsui-polaris-dark-mode awsui-polaris-comfortable-mode'
  const [sequence, setSequence] = useState(StartSequence.START)
  const [authUser, setAuthUser] = useState<AuthUser>()
  const [token, setToken] = useState<string>()
  const [userInfo, setUserInfo] = useState<JsonObject>()
  const [error, setError] = useState<{ type: string | null | undefined, description: string | null | undefined }>()
  const logger = useContext(LoggerContext)
  const auth = useContext(AuthContext)

  useEffect(() => {

    Hub.listen('auth', (payload)=> {
      logger.warn({payload} , 'Amplify event')
    })
    const processState = (sequence: StartSequence) => {
      logger.info({sequence}, '')
      if (sequence !== StartSequence.ERROR && location.search.startsWith('?error')) {
        const params = new URLSearchParams(location.search?.substring(1) ?? '')
        setError({
          type: params.get('error'),
          description: params.get('error_description')
        })
        setSequence(StartSequence.ERROR)
      }
      if (sequence === StartSequence.START) {
        setSequence(StartSequence.INIT)
        auth.loadConfiguration(Amplify)
      } else if (sequence === StartSequence.INIT) {
        auth.isConfigured()
          .then(() => setSequence(StartSequence.LOGGING_IN))
      } else if (sequence === StartSequence.LOGGING_IN) {
        return auth.getCurrentUser()
          .then(user => {
              setAuthUser(user)
            if (user) {
              setSequence(StartSequence.LOGGED_IN)
            } else {
              const cancel = Hub.listen('auth', (data) => {
                if (data.payload.event === 'signedIn') {
                  auth.getCurrentUser()
                    .then(user => {
                      setAuthUser(user)
                      setSequence(StartSequence.LOGGED_IN)
                      cancel()
                    })
                }
              })
            }
          }).catch(err => {
            // eslint-disable-next-line no-console
            logger.error(err)
            if(err.message.startsWith('Timeout')) {
              setError({type: 'timeout', description: 'OIDC sign in timed out.'})
              setSequence(StartSequence.ERROR)
            }
          })
      } else if (sequence === StartSequence.LOGGED_IN) {
        setSequence(StartSequence.LOADING_PROFILE)
        return auth.getUser()
          .then(user => setUserInfo(user))
          .then(() => auth.getIdToken())
          .then(token => setToken(token?.toString))
      } else if (sequence === StartSequence.LOADING_PROFILE) {
        if (userInfo && token) {
          setSequence(StartSequence.PROFILE_LOADED)
        }
      } else if (sequence === StartSequence.PROFILE_LOADED) {
        UserApi.putUser().then(user => logger.info({user}, 'Obtained OneTeam user'))
        logger.logEvent({
          type: 'ApplicationStarted',
          user: userInfo?.nickname,
          email: userInfo?.email,
          startTime: new Date().toISOString()
        })
        setSequence(StartSequence.COMPLETE)
      }
    }
    processState(sequence)
  }, [sequence, token, userInfo, authUser, error])
  return (<>
    {
      sequence !== StartSequence.COMPLETE && sequence !== StartSequence.ERROR
        ? <LoadingIndicator/>
        : sequence === StartSequence.ERROR
          ? <ErrorMessage {...error} />
          : <OneTeam user={ensure(userInfo)} token={ensure(token)}/>
    }
  </>)
}
