import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import {captureAxios} from './api/xray'
import axios from 'axios'
import Logger, {LoggerContext} from './common/logger'
import config from './config'
import AuthContext, {AmplifyAuth} from './common/amplifyAuth'
import * as bunyan from 'browser-bunyan'

captureAxios(axios)

const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
)
const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthContext.Provider value={new AmplifyAuth()}>
      <LoggerContext.Provider value={new Logger(config.appName, bunyan.DEBUG)}>
        <App/>
      </LoggerContext.Provider>
    </AuthContext.Provider>,
    children: [{
      path: 'dashboard',
      element: <div>Dashboard</div>
    }, {
      path: 'huk',
      element: <div>HUK</div>
    }, {
      path: 'devk',
      element: <div>DEVK</div>
    }, {
      path: 'msg',
      element: <div>msg</div>
    }]
  }, {
    path: '/error',
    element: <div>Error</div>
  }
])
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
