import axios, {
  Axios,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios'
import config from '../config'
const traceHeader = 'X-Amzn-Trace-Id'
const contextHeader = 'X-Trace-Context'

const crypto = window.crypto
type Segment = Record<string, string | number | boolean |
  Record<string, string | number | boolean |
    Record<string, string | number>>>

export class XRay {
  endSegment(segment: Segment) {
    segment.end_time = this.getEpochTime()
    segment.in_progress = false
    const documents: string[] = []
    documents[0] = JSON.stringify(segment)
    this.putDocuments(documents)
  }

  putDocuments(documents: string[]) {
    const headers = {...axios.defaults.headers.common}
    if(headers.Authorization !== undefined) {
      delete headers[traceHeader]
      headers[contextHeader] = config.appName
      const ax = axios.create({headers})
      // noinspection JSIgnoredPromiseFromCall
      ax.post(config.apiUrl + '/trace', {documents})
    }
  }

  getHexId(length: number) {
    const bytes = new Uint8Array(length)
    crypto.getRandomValues(bytes)
    let hex = ''
    for (let i = 0; i < bytes.length; i++) {
      hex += bytes[i].toString(16)
    }
    return hex.substring(0, length)
  }

  getHexTime() {
    return Math.round(new Date().getTime() / 1000).toString(16)
  }

  getEpochTime() {
    return new Date().getTime() / 1000
  }

  getTraceHeader(segment: Segment) {
    return 'Root=' + segment.trace_id + ';Parent=' + segment.id + ';Sampled=1'
  }

  beginSegment() {
    const segment =
      {} as Segment
    const traceId = '1-' + this.getHexTime() + '-' + this.getHexId(24)

    const id = this.getHexId(16)
    const startTime = this.getEpochTime()

    segment.trace_id = traceId
    segment.id = id
    segment.start_time = startTime
    segment.name = 'AWSOneTeam-client'
    segment.in_progress = true

    segment.http = {
      request: {
        user_agent: navigator.userAgent,
        client_ip: window.location.host,
      },
      response: {}
    }

    const documents: string[] = []
    documents[0] = JSON.stringify(segment)
    this.putDocuments(documents)
    return segment
  }
}

let segment: Segment | undefined
export const captureAxios = (axios: Axios) => {
  const xray = new XRay()
  const requestHandler = (config: InternalAxiosRequestConfig) => {
    if (config.headers[contextHeader] === undefined) {
      segment = xray.beginSegment()
      if(segment) {
        const request = (segment.http as Record<string, Record<string,string|number|undefined>>).request
        request.method = config.method?.toUpperCase()
        request.url = config.url
      }
      config.headers[traceHeader] = xray.getTraceHeader(segment)
    }
    return config
  }

  const responseHandler = (response: AxiosResponse) => {
    if (segment) {
      (segment.http as Record<string, Record<string,string|number>>)['response'] = {
        status: response.status,
        content_length: response.data.length
      }
      xray.endSegment(segment)
    }
    segment = undefined
    return response
  }

  const rejectionHandler = (error: Error) => {
    if (segment) {
      xray.endSegment(segment)
    }
    return Promise.reject(error)
  }

  axios.interceptors.request.use(requestHandler, rejectionHandler)
  axios.interceptors.response.use(responseHandler, rejectionHandler)
}
