import {
  ConfigApi,
  LoadedRuntimeConfiguration,
  RuntimeConfiguration
} from '../api/config-api'
import {
  Amplify as DefaultAmplify,
  ResourcesConfig
} from 'aws-amplify'
import {createContext,} from 'react'
import {
  AuthTokens,
  AuthUser,
  fetchAuthSession,
  getCurrentUser,
  JWT,
  signInWithRedirect,
  signOut
} from '@aws-amplify/auth'
import axios from 'axios'
import {ensure} from '../util/ensure'
import {cognitoUserPoolsTokenProvider} from '@aws-amplify/auth/cognito'

export interface Auth {
  isConfigured(): Promise<unknown>

  getCurrentUser(): Promise<AuthUser|undefined>

  getTokens(): Promise<AuthTokens|undefined>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getIdToken(): Promise<any>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUser(): Promise<any>

  signInFederated(): Promise<void>

  signOut(): Promise<void>

  loadConfiguration(Amplify?: typeof DefaultAmplify): Promise<void>
}

export class AmplifyAuth implements Auth {
  private config?: ResourcesConfig
  private authUser?: AuthUser
  private authTokens?: AuthTokens
  private idToken?: JWT
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private user: any
  private loading = false

  async isConfigured() {
    return new Promise(resolve => {
      if (this.config) {
        resolve(true)
      } else setTimeout(() => {
        this.isConfigured().then(result => resolve(result))
      }, 50)
    })
  }

  async getCurrentUser() {
    if (!this.authUser) {
      this.authUser = await getCurrentUser()
        .catch(err => {
          if (err.name === 'UserUnAuthenticatedException') {
            if (!window.location.search.startsWith('?code')) {
              this.signInFederated()
            }
            return undefined
          } else {
            // eslint-disable-next-line no-console
            console.log(err)
            throw new Error(err.name)
          }
        })
    }
    return this.authUser
  }

  async getTokens() {
    if (!this.authTokens) {
      this.authTokens = (await fetchAuthSession()).tokens
    }
    return this.authTokens
  }

  async getIdToken() {
    if (!this.idToken) {
      this.idToken = (await this.getTokens())?.idToken
      if (this.idToken) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.idToken.toString()
      }
    }
    return this.idToken
  }

  async getUser() {
    if (!this.user) {
      this.user = (await this.getIdToken())?.payload
    }
    return this.user
  }

  async signInFederated() {
    await this.signOut()
    return signInWithRedirect({provider: {custom: 'AmazonFederated'}})
  }

  async signOut() {
    return signOut({global: true})
  }

  async loadConfiguration(Amplify?: typeof DefaultAmplify) {
    if(!this.loading) {
      this.loading = true
      const runtimeConfiguration = await ConfigApi.loadRuntimeConfiguration()
      this.config = configureAmplifyAuthentication(ensure(Amplify), runtimeConfiguration, window?.location.host)
    }
  }
}

export const configureAmplifyAuthentication = (Amplify:typeof DefaultAmplify,
                                               loadedConfig: LoadedRuntimeConfiguration,
                                               host = 'localhost:3000') => {
  if (loadedConfig.Auth) {
    const appliedConfig: RuntimeConfiguration = {
      Auth:
        {
          guestRoleArn: loadedConfig.Auth.guestRoleArn,
          Cognito: {
            ...loadedConfig.Auth.Cognito,
            loginWith: {
              oauth: {
                ...loadedConfig.Auth.Cognito.loginWith.oauth,
                redirectSignIn: [],
                redirectSignOut: [],
                scopes: []
              }
            },
          },
        },
    }
    appliedConfig.Auth.Cognito.loginWith.oauth.redirectSignIn =
      loadedConfig.Auth.Cognito.loginWith.oauth.redirectSignIn.split(',').filter(url => url.includes(host))
    appliedConfig.Auth.Cognito.loginWith.oauth.redirectSignOut =
      loadedConfig.Auth.Cognito.loginWith.oauth.redirectSignOut.split(',').filter(url => url.includes(host))
    appliedConfig.Auth.Cognito.loginWith.oauth.scopes =
      loadedConfig.Auth.Cognito.loginWith.oauth.scopes.split(',')
    Amplify.configure(appliedConfig)
    const config = Amplify.getConfig()
    cognitoUserPoolsTokenProvider.setAuthConfig(ensure(config?.Auth))
    return config
  }
}

export class MockAuth implements Auth {
  private dummyToken = {toString: () => 'test'} as unknown as JWT


  async isConfigured(): Promise<boolean> {
    return Promise.resolve(true)
  }

  async getCurrentUser(): Promise<AuthUser | undefined> {
    return {signInDetails: undefined, userId: 'test', username: 'test'}
  }

  async getTokens(): Promise<AuthTokens> {
    return {accessToken: this.dummyToken, idToken: this.dummyToken}
  }

  async getIdToken(): Promise<JWT | undefined> {
    return this.dummyToken
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getUser(): Promise<any> {
    return {nickname: 'TestUser', email: 'test@test.de'}
  }

  async signInFederated(): Promise<void> {
    return
  }

  async signOut(): Promise<void> {
    return
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async loadConfiguration(Amplify?: typeof DefaultAmplify): Promise<void> {
    return
  }
}

const AuthContext = createContext(new MockAuth() as Auth)
export default AuthContext