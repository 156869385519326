import axios from 'axios'
import config from '../config'
import {v4 as uuid} from 'uuid'

export interface LogMessage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any,
  timestamp: number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createLogMessage = (payload: any) => {
  return ({payload, timestamp: new Date().valueOf()})
}

export default class LogApi {
  // post to /log via axios
  static async postLog(messages: LogMessage[]) {
    const body = {
      messages,
      logStreamName: `${config.appName}-${uuid()}`
    }
    await axios.post(`${config.apiUrl}/log`, body)
  }
}
