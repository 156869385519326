import axios from 'axios'
import config from '../config'

export interface User {
  userId: string
  email: string
  nickName: string
  givenName: string
  familyName: string
  jobTitle: string
  jobLevel: string
  isManager: boolean
  badgeColorCode: string
  badgeColor: string
  joinedAmzn: string
  department: string
  businessUnit: string
  manager: string
  costCenter?: string
  location: string
  country: string
  city: string
  roomNumber?: string
  deskPhone?: string
  mobilePhone?: string
  isBarRaiser?: boolean
}


export default class UserApi {
  static async putUser () {
    const result = await axios.put(config.apiUrl+ '/user') // token is passed via Authorization header
    return result.data as User
}
}