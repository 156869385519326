import React from 'react'
import {FlashbarProps} from '@amzn/awsui-components-react/polaris/flashbar'

type Notification = FlashbarProps.MessageDefinition
export interface NotificationHandlerProps {
  notifications: Notification[]
  setNotifications: (notifications: Notification[]) => void
  dismissNotification: (id: string) => void
}
const provideHandler = (
  props: NotificationHandlerProps,
) => (event: MessageEvent) => {
  const payload = JSON.parse(event.data)
  const notification: Notification = {
    id: payload.id,
    type: payload.level ?? 'info',
    statusIconAriaLabel: payload.level ?? 'info',
    header: payload.title,
    content: (<>
        {payload.content}
      </>),
    dismissible: true,
    dismissLabel: 'Dismiss message',
    onDismiss: () => props.dismissNotification(payload.id),
  }
  const nextNotifications = [...props.notifications, notification]
  props.setNotifications(nextNotifications)
}

export default provideHandler