import axios from 'axios'

export interface RuntimeConfiguration {
  Auth: {
    guestRoleArn: string,
    Cognito: {
      userPoolId: string,
      userPoolClientId: string,
      identityPoolId: string,
      loginWith: {
        oauth: {
          domain: string,
          scopes: string[],
          redirectSignIn: string[],
          redirectSignOut: string[],
          responseType: 'code'|'token'
        }
      }
    }
  }
}

export interface LoadedRuntimeConfiguration {
  Auth: {
    guestRoleArn: string,
    Cognito: {
      userPoolId: string,
      userPoolClientId: string,
      identityPoolId: string,
      loginWith: {
        oauth: {
          domain: string,
          scopes: string,
          redirectSignIn: string,
          redirectSignOut: string,
          responseType: 'code'|'token'
        }
      }
    }
  }
}
export class ConfigApi{
  static async loadRuntimeConfiguration(): Promise<LoadedRuntimeConfiguration> {
    return await axios.get('/runtime-config.json')
      .then(res => res.data as LoadedRuntimeConfiguration)
  }
}